import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import "firebase/compat/analytics";

const config = {
    apiKey: 'AIzaSyA5gJCvnw6QysmQ7-bcSYBV15frB8KDO1w',
    authDomain: 'health-ahoy.firebaseapp.com',
    projectId: 'health-ahoy',
    storageBucket: 'health-ahoy.appspot.com',
    messagingSenderId: '339965187974',
    appId: '1:339965187974:web:5a4e6d2d32498e51413e07',
    measurementId: 'G-G7CPZ3Z7QT'
};

const firebase = Firebase.initializeApp(config);
const { FieldValue } = Firebase.firestore;
const analytics = firebase.analytics();

export { firebase, analytics, FieldValue };
